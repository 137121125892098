<template lang="pug">
IonPage(class="sign-in-page")
    IonContent(
        style="min-height: 100vh"
        class="relative"
    )
        Container(
            class="d-flex flex-column justify-end"
            style="height: 100%"
        )
            div
                div
                    h1(
                        style="font-weight: 400; font-size: 30px"
                        class="mb-2"
                    ) Symbiotic.App
                    h3 Login

                Spacer
                Spacer

                //- user
                div(class="mb-3")
                    IonItem(
                        class="radius"
                        lines="none"
                    )
                        IonLabel(
                            position="floating"
                            style="--color: var(--ion-color-primary)"
                        ) Benutzer
                        IonInput(
                            mode="md"
                            style="--color: var(--ion-color-primary)"
                        )

                //- password
                div(class="relative")
                    IonItem(
                        class="radius"
                        lines="none"
                    )
                        IonLabel(
                            position="floating"
                            style="--color: var(--ion-color-primary)"
                        ) Passwort
                        IonInput(
                            mode="md"
                            :type="!showPassword ? 'password' : ''"
                            style="--color: var(--ion-color-primary)"
                        )
                    div(
                        class="d-flex align-center justify-center"
                        style="height: 100%; margin: 0; position: absolute; top: 0; right: 0; width: 60px; z-index: 1"
                    )
                        IonIcon(
                            :icon="showPassword ? eyeOffOutline : eyeOutline"
                            @click.stop="toggleShowPassword"
                            color="secondary"
                            size="large"
                        )

                IonItem(
                    style="--background: none; width: 100%; --border-style: none; --padding-start: 0"
                )
                    IonCheckbox(
                        mode="md"
                        slot="start"
                        style="--border-color: white; margin-right: 8px"
                        v-model="savePassword"
                    )
                    IonLabel Passwort speichern

                Spacer

                IonButton(
                    color="secondary"
                    fill="solid"
                    size="large"
                    style="width: 100%"
                    @click="logUserInProceed"
                )
                    IonText(
                        color="primary"
                    )
                        h3
                            div Anmelden

                Spacer
                Spacer
                Spacer
                Spacer

        div(
            class="bg-image-wrapper d-flex align-center justify-center"
            style="overflow: hidden"
        )
            img(
                src="@/assets/images/bg-logo.png"
            )
</template>

<script>
import { ref, getCurrentInstance } from "vue"
import { useRouter } from "vue-router"
import { eyeOutline, eyeOffOutline } from "ionicons/icons"
import { useAuth } from "@/composables/auth"
import { useUtilities } from "@/composables/utilities"
export default {
    setup() {
        // show password
        eyeOutline
        eyeOffOutline

        const showPassword = ref(false)

        function toggleShowPassword() {
            showPassword.value = !showPassword.value
        }

        // authentication
        const emitter = useUtilities().getEmitter()

        const router = useRouter()

        const savePassword = ref(false)

        const { logUserIn } = useAuth()
        const { saveUser } = useAuth()

        function logUserInProceed() {
            emitter.emit("setLoadingModal", true)
            logUserIn()
            if (!!savePassword.value) saveUser()
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
            router.push({ name: "home" })
        }

        return {
            showPassword,
            toggleShowPassword,
            eyeOutline,
            eyeOffOutline,
            logUserInProceed,
            savePassword
        }
    }
}
</script>

<style lang="sass" scoped >
ion-content
    --background: var(--ion-color-primary)
.bg-image-wrapper
    width: 100%
    height: 100%
    max-height: 100vh
    position: absolute
    top: 0
    left: 0
    z-index: -1
    pointer-events: none
    img
        width: 100%
        height: auto
        transform: scale(1.7)
        // opacity: 0.05
</style>